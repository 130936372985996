:root {
  --body-bold-font-family: "SF Pro Text", Helvetica;
  --body-bold-font-size: 17px;
  --body-bold-font-style: normal;
  --body-bold-font-weight: 400;
  --body-bold-letter-spacing: -.408px;
  --body-bold-line-height: 22px;
  --content-bold-font-family: "Aeonik TRIAL", Helvetica;
  --content-bold-font-size: .857vw;
  --content-bold-font-style: normal;
  --content-bold-font-weight: 700;
  --content-bold-letter-spacing: .32px;
  --content-bold-line-height: normal;
  --content-regular-font-family: "Aeonik TRIAL", Helvetica;
  --content-regular-font-size: 16px;
  --content-regular-font-style: normal;
  --content-regular-font-weight: 400;
  --content-regular-letter-spacing: .32px;
  --content-regular-line-height: normal;
  --content-title-bold-font-family: "Aeonik TRIAL", Helvetica;
  --content-title-bold-font-size: 20px;
  --content-title-bold-font-style: normal;
  --content-title-bold-font-weight: 400;
  --content-title-bold-letter-spacing: 0px;
  --content-title-bold-line-height: normal;
  --danger: #fa5959;
  --defaultsystembluedark: #0a84ff;
  --defaultsystembluelight: #007aff;
  --defaultsystemgreendark: #30d158;
  --defaultsystemgreenlight: #34c759;
  --defaultsystemorangedark: #ff9f0a;
  --defaultsystemorangelight: #ff9500;
  --h1-bold-font-family: "Aeonik TRIAL", Helvetica;
  --h1-bold-font-size: 110px;
  --h1-bold-font-style: normal;
  --h1-bold-font-weight: 700;
  --h1-bold-letter-spacing: 0px;
  --h1-bold-line-height: normal;
  --h2-bold-font-family: "Aeonik TRIAL", Helvetica;
  --h2-bold-font-size: 90px;
  --h2-bold-font-style: normal;
  --h2-bold-font-weight: 700;
  --h2-bold-letter-spacing: 0px;
  --h2-bold-line-height: normal;
  --h3-bold-font-family: "Aeonik TRIAL", Helvetica;
  --h3-bold-font-size: 3.5vw;
  --h3-bold-font-style: normal;
  --h3-bold-font-weight: 700;
  --h3-bold-letter-spacing: 0px;
  --h3-bold-line-height: normal;
  --h4-bold-font-family: "Aeonik TRIAL", Helvetica;
  --h4-bold-font-size: 40px;
  --h4-bold-font-style: normal;
  --h4-bold-font-weight: 700;
  --h4-bold-letter-spacing: 0px;
  --h4-bold-line-height: normal;
  --ios-000000: #000;
  --ios-202020: #202020;
  --ios-3a-3b-3d: #3a3b3d;
  --ios-434343: #434343;
  --ios-5055-5c: #50555c;
  --ios-565455: #565455;
  --ios-default-body-font-family: "SF Pro Text", Helvetica;
  --ios-default-body-font-size: 17px;
  --ios-default-body-font-style: normal;
  --ios-default-body-font-weight: 400;
  --ios-default-body-letter-spacing: 0px;
  --ios-default-body-line-height: 20px;
  --ios-default-title-2-font-family: "SF Pro Display", Helvetica;
  --ios-default-title-2-font-size: 22px;
  --ios-default-title-2-font-style: normal;
  --ios-default-title-2-font-weight: 400;
  --ios-default-title-2-letter-spacing: 0px;
  --ios-default-title-2-line-height: 28px;
  --ios-key-box-shadow-dark: 0px 1px 0px 0px #000;
  --ios-key-box-shadow-light: 0px 1px 0px 0px #898a8d;
  --ios-medium-body-font-family: "SF Pro Text", Helvetica;
  --ios-medium-body-font-size: 16px;
  --ios-medium-body-font-style: normal;
  --ios-medium-body-font-weight: 400;
  --ios-medium-body-letter-spacing: 0px;
  --ios-medium-body-line-height: 21px;
  --ios-number-keys-subtext-font-family: "SF Pro Text", Helvetica;
  --ios-number-keys-subtext-font-size: 10px;
  --ios-number-keys-subtext-font-style: normal;
  --ios-number-keys-subtext-font-weight: 700;
  --ios-number-keys-subtext-letter-spacing: 2px;
  --ios-number-keys-subtext-line-height: 12px;
  --ios-xsmall-title-1-font-family: "SF Pro Display", Helvetica;
  --ios-xsmall-title-1-font-size: 25px;
  --ios-xsmall-title-1-font-style: normal;
  --ios-xsmall-title-1-font-weight: 400;
  --ios-xsmall-title-1-letter-spacing: 0px;
  --ios-xsmall-title-1-line-height: 30px;
  --iosadb-3bc: #adb3bc;
  --iosalfa202020-92: #202020eb;
  --iosalfaccced3-76: #ccced3c2;
  --iose-7e-7e-7: #e7e7e7;
  --iosebedf-0: #ebedf0;
  --iosffffff: #fff;
  --label-colordarkprimary: #fff;
  --label-colorlightprimary: #000;
  --mobile-10px-font-family: "Aeonik TRIAL", Helvetica;
  --mobile-10px-font-size: 10px;
  --mobile-10px-font-style: italic;
  --mobile-10px-font-weight: 400;
  --mobile-10px-letter-spacing: 0px;
  --mobile-10px-line-height: normal;
  --note-regular-font-family: "Aeonik TRIAL", Helvetica;
  --note-regular-font-size: 14px;
  --note-regular-font-style: normal;
  --note-regular-font-weight: 400;
  --note-regular-letter-spacing: 0px;
  --note-regular-line-height: normal;
  --notes-bold-font-family: "Aeonik TRIAL", Helvetica;
  --notes-bold-font-size: 14px;
  --notes-bold-font-style: normal;
  --notes-bold-font-weight: 700;
  --notes-bold-letter-spacing: 0px;
  --notes-bold-line-height: normal;
  --primary-100: #fcf6da;
  --primary-500main: #ffba15;
  --primary-600: #f90;
  --secondary-300: #c7e0ff;
  --small-title-bold-font-family: "Aeonik TRIAL", Helvetica;
  --small-title-bold-font-size: 28px;
  --small-title-bold-font-style: normal;
  --small-title-bold-font-weight: 700;
  --small-title-bold-letter-spacing: 0px;
  --small-title-bold-line-height: normal;
  --small-title-regular-font-family: "Aeonik TRIAL", Helvetica;
  --small-title-regular-font-size: 28px;
  --small-title-regular-font-style: normal;
  --small-title-regular-font-weight: 400;
  --small-title-regular-letter-spacing: 0px;
  --small-title-regular-line-height: normal;
  --subheadline-bold-font-family: "SF Pro Text", Helvetica;
  --subheadline-bold-font-size: 15px;
  --subheadline-bold-font-style: normal;
  --subheadline-bold-font-weight: 400;
  --subheadline-bold-letter-spacing: -.5px;
  --subheadline-bold-line-height: 20px;
  --success: #4dbd58;
  --system-backgrounddark-baseprimary: #000;
  --text-150: #fafafa;
  --text-300: #fafafa;
  --text-500: #d4d4d4;
  --text-600: #989898;
  --text-800: #626262;
  --text-900: #202020;
  --themes-black-100: #1c1c1c;
  --white: #fff;
}

@media (width >= 2000px) {
  :root {
    --h3-bold-font-size: 6vh;
  }
}

[data-themes-mode="pastel-light"] {
  --themes-black-100: #1c1c1c;
}

[data-themes-mode="pastel-dark"] {
  --themes-black-100: #fff;
}

[data-themes-mode="bright-light"] {
  --themes-black-100: #2e343e;
}

[data-themes-mode="bright-dark"] {
  --themes-black-100: #fff;
}

.wallet-adapter-button-trigger {
  background-color: #ffba15;
}

@media (width <= 786px) {
  .wallet-adapter-button {
    height: 40px;
  }
}

.wallet-adapter-button {
  color: #fff;
  box-sizing: border-box;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 2vh;
  height: 6vh;
  padding: 10px;
  font-size: 18px;
  display: flex;
}
/*# sourceMappingURL=index.66b22ce3.css.map */
